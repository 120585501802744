import { RouterProvider, createBrowserRouter } from "react-router-dom";
import AcademicYear from "../pages/AcademicYear";
import Appointment from "../pages/Appointment";
import AppointmentType from "../pages/AppointmentType";
import Attendance from "../pages/Attendance";
import BackupSession from "../pages/BackupSession";
import Batch from "../pages/Batch";
import Board from "../pages/Board";
import Branch from "../pages/Branch";
import BranchUser from "../pages/BranchUser";
import Dashboard from "../pages/Dashboard";
import Day from "../pages/Day";
import Department from "../pages/Department";
import Designation from "../pages/Designation";
import ErrorPage from "../pages/ErrorPage";
import FeesDiscountMaster from "../pages/Fees/FeesDiscountMaster";
import FeesMaster from "../pages/Fees/FeesMaster";
import Gender from "../pages/Gender";
import Lecture from "../pages/Lecture";
import BranchDistanceTimeMatrix from "../pages/LecturePlanningMatrix/BranchDistanceTimeMatrix";
import CustomKeyboard from "../pages/LecturePlanningMatrix/CustomKeyboard";
import ManageBranch from "../pages/LecturePlanningMatrix/ManageBranch";
import ManageTeacher from "../pages/LecturePlanningMatrix/ManageTeacher";
import Login from "../pages/Login";
import Parent from "../pages/Parent";
import Password from "../pages/Password";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Profile from "../pages/Profile";
import Relation from "../pages/Relation";
import PaymentReport from "../pages/Report/PaymentReport";
import { AttendanceReport } from "../pages/Report/Report";
import School from "../pages/School";
import Shift from "../pages/Shift";
import Staff from "../pages/Staff";
import Standard from "../pages/Standard";
import Stream from "../pages/Stream";
import Student from "../pages/Student";
import StudyMaterial from "../pages/StudyMaterial";
import StudyMaterialType from "../pages/StudyMaterialType";
import Subject from "../pages/Subject";
import Teacher from "../pages/Teacher";
import TermsAndConditions from "../pages/TermsAndConditions";
import Test from "../pages/Test";
import TimeSlot from "../pages/TimeSlot";
import Year from "../pages/Year";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/branch",
    element: <Branch />,
  },
  {
    path: "/designation",
    element: <Designation />,
  },
  {
    path: "/department",
    element: <Department />,
  },
  {
    path: "/subject",
    element: <Subject />,
  },
  {
    path: "/standard",
    element: <Standard />,
  },
  {
    path: "/stream",
    element: <Stream />,
  },
  {
    path: "/year",
    element: <Year />,
  },
  {
    path: "/board",
    element: <Board />,
  },
  {
    path: "/school",
    element: <School />,
  },
  {
    path: "/relation",
    element: <Relation />,
  },
  {
    path: "/gender",
    element: <Gender />,
  },
  {
    path: "/academicyear",
    element: <AcademicYear />,
  },
  {
    path: "/batch",
    element: <Batch />,
  },
  {
    path: "/shift",
    element: <Shift />,
  },
  {
    path: "/student",
    element: <Student />,
  },
  {
    path: "/teacher",
    element: <Teacher />,
  },
  {
    path: "/lecture",
    element: <Lecture />,
  },
  {
    path: "/attendance",
    element: <Attendance />,
  },
  {
    path: "/parent",
    element: <Parent />,
  },
  {
    path: "/password",
    element: <Password />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/staff",
    element: <Staff />,
  },
  {
    path: "/branchUser",
    element: <BranchUser />,
  },
  {
    path: "/studyMaterial",
    element: <StudyMaterial />,
  },
  {
    path: "/timeSlot",
    element: <TimeSlot />,
  },
  {
    path: "/appointmentType",
    element: <AppointmentType />,
  },
  {
    path: "/appointment",
    element: <Appointment />,
  },
  {
    path: "/test",
    element: <Test />,
  },
  {
    path: "/backupSession",
    element: <BackupSession />,
  },
  {
    path: "/studyMaterialType",
    element: <StudyMaterialType />,
  },
  {
    path: "/lecturePlanningMatrix",
    element: <CustomKeyboard />,
  },
  {
    path: "/day",
    element: <Day />,
  },
  {
    path: "/manageBranch",
    element: <ManageBranch />,
  },
  {
    path: "/manageTeacher",
    element: <ManageTeacher />,
  },
  {
    path: "/branchDistanceTimeMatrix",
    element: <BranchDistanceTimeMatrix />,
  },
  {
    path: "/branchAdmin",
    element: <BranchUser />,
  },
  {
    path: "/report/attendance",
    element: <AttendanceReport />,
  },
  {
    path: "/report/payment",
    element: <PaymentReport />,
  },
  {
    path: "/fees",
    element: <FeesMaster />,
  },
  {
    path: "/feesDiscount",
    element: <FeesDiscountMaster />,
  },
  {
    path: "/privacyPolicy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/termsAndConditions",
    element: <TermsAndConditions />,
  },
]);

const RouteProvider = () => {
  return <RouterProvider router={router}></RouterProvider>;
};

export default RouteProvider;
