import { Formik } from "formik";
import moment from "moment";
import queryString from "query-string";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import useFetch, { FetchData } from "use-http";
import * as Yup from "yup";
import AppProvider from "../../components/AppProvider";
import CustomPageLayout from "../../components/CustomPageLayout";
import {
  AddDesignationRoot,
  GetAllDesignationAPIRoot,
  GetAllStudentDaum,
  GetAllStudentRoot,
  GetFeesDiscountMasterByIdRoot,
  GetFeesDiscountMasterDaum,
  GetFeesDiscountMasterRoot,
  GetFeesMasterByIdRoot,
} from "../../utils/types";

const FeesDiscountMaster = () => {
  return (
    <CustomPageLayout Add={Add} Index={Index} Update={Update} View={View} />
  );
};

const Index = () => {
  const navigate = useNavigate();
  const {
    get,
    delete: deleteDesignation,
    response,
    loading,
  } = useFetch<GetFeesDiscountMasterRoot>("/feesDiscountMaster");

  const [refreshing, setRefreshing] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    (async () => {
      setRefreshing(true);

      await get()
        .then((res) => {
          if (res) {
            if (res.success) {
              setRefreshing(false);
            }
          }
        })
        .catch((err) => console.log(err));
    })();
  }, [get]);

  const getData = async () => {
    setRefreshing(true);

    await get()
      .then((res) => {
        if (res) {
          if (res.success) {
            setRefreshing(false);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleDelete = async (_id: string) => {
    setRefreshing(true);

    await deleteDesignation(`${_id}`).then((res) => {
      if (res.success) {
        Swal.fire({
          title: "Deleted!",
          text: "Your data has been deleted.",
          icon: "success",
        });
      }
    });
    await getData();

    setRefreshing(false);
  };
  const handlePageChange = ({ selected }: { selected: number }) => {
    setCurrentPage(selected);
  };

  if (loading || refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (!response.ok) {
    return <div>...</div>;
  }
  const currentData = response?.data?.data.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );
  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "3rem",
                    alignItems: "center",
                  }}
                >
                  <h6>Fees Discount</h6>

                  {true && (
                    <Button
                      size="sm"
                      onClick={() => navigate("/feesDiscount?action=add")}
                    >
                      ADD
                    </Button>
                  )}
                </div>
              </Card.Header>

              <Card.Body>
                <Table bordered hover responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Student</th>
                      <th>Standard</th>
                      <th>Year</th>
                      <th>Fees</th>
                      <th>Discount</th>
                      <th>Total</th>
                      <th>Is Active</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData &&
                      currentData.map(
                        (item: GetFeesDiscountMasterDaum, index: number) => (
                          <tr key={item._id}>
                            <td>{index + 1 + currentPage * itemsPerPage}</td>
                            <td>{item?.student?.name || ""}</td>
                            <td>{item?.standard?.name || ""}</td>
                            <td>
                              {moment(item?.year, "YYYY-MM-DD").format(
                                "YYYY"
                              ) || ""}
                            </td>
                            <td>{item?.fees || ""}</td>
                            <td>{item?.discount || ""}</td>
                            <td>{item?.total || ""}</td>
                            <td>{item.isActive ? "Yes" : "No"}</td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "1rem",
                                }}
                              >
                                <Button
                                  size="sm"
                                  onClick={() =>
                                    navigate(
                                      `/feesDiscount?action=update&&id=${item._id}`
                                    )
                                  }
                                >
                                  UPDATE
                                </Button>
                                <Button
                                  size="sm"
                                  variant="danger"
                                  onClick={() => {
                                    Swal.fire({
                                      title: "Are you sure?",
                                      text: "You won't be able to revert this!",
                                      icon: "warning",
                                      showCancelButton: true,
                                      confirmButtonColor: "#3085d6",
                                      cancelButtonColor: "#d33",
                                      confirmButtonText: "Yes, delete it!",
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        handleDelete(item._id);
                                      }
                                    });
                                  }}
                                >
                                  DELETE
                                </Button>
                              </div>
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Footer>
                <Row>
                  <Col>
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={Math.ceil(
                        (response.data?.data.length || 0) / itemsPerPage
                      )}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const Add = () => {
  const navigate = useNavigate();
  const { post } = useFetch<AddDesignationRoot>("/feesDiscountMaster");
  const { post: getByStandardAndYear, response: getByStandardAndYearResponse } =
    useFetch<GetFeesMasterByIdRoot>("/feesMaster");
  const { get: getBranch, response: getBranchResponse } =
    useFetch<GetAllDesignationAPIRoot>("/branch");
  const { post: getStudentByBranch, response: getStudentResponse } =
    useFetch<GetAllStudentRoot>("/student");
  const { get: getStandard, response: getStandardResponse } =
    useFetch<GetAllDesignationAPIRoot>("/standard");
  const [isFetching, setIsFetching] = useState(false);
  const [key, setKey] = useState(Math.random());

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [getBranch]);

  const fetchData = async () => {
    try {
      setIsFetching(true);

      await getBranch();
      await getStandard();

      setKey(Math.random());

      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
    }
  };

  const handleSubmit = async (values: {
    student: String;
    standard: String;
    branch: String;
    year: String;
    fees: number;
    discount: number;
    total: number;
    isActive: Boolean;
  }) => {
    try {
      setIsFetching(true);

      const res = await post(values);

      if (res.success) {
        navigate(-1);
      } else {
        Swal.fire({
          title: "Error",
          text: res.message || "Something went wrong on server.",
          icon: "error",
        });
      }

      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);

      console.log(err);
    }
  };

  if (isFetching) {
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <AppProvider>
        <Container
          fluid
          style={{
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Card>
            <Card.Body>
              <Spinner />
            </Card.Body>
          </Card>
        </Container>
      </AppProvider>
    </div>;
  }

  return (
    <>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Add Fees</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    student: Yup.string().required(),
                    standard: Yup.string().required(),
                    branch: Yup.string().required(),
                    year: Yup.string().required(),
                    fees: Yup.number().min(0).required(),
                    discount: Yup.number().min(0).max(100).required(),
                    total: Yup.number().min(0).required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={{
                    student: "",
                    standard: "",
                    branch: "",
                    year: "",
                    fees: 0,
                    discount: 0,
                    total: 0,
                    isActive: true,
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    errors,
                    setFieldValue,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <GetStudentByBranch
                        branchId={values.branch}
                        getStudentByBranch={getStudentByBranch}
                        setFieldValue={setFieldValue}
                        name={"student"}
                      />

                      <SetStandardByStudent
                        name="standard"
                        setFieldValue={setFieldValue}
                        studentData={getStudentResponse.data?.data || []}
                        studentId={values.student}
                      />

                      <GetFeesByStandardAndYear
                        getStandardByStandardAndYear={getByStandardAndYear}
                        standard={values.standard}
                        year={values.year}
                        setFieldValue={setFieldValue}
                        key={values.year || values.standard}
                      />

                      <CalculateTotal
                        discount={values.discount}
                        fees={values.fees}
                        setFieldValue={setFieldValue}
                      />

                      <Table>
                        <tbody>
                          <tr>
                            <th>Branch</th>
                            <th>
                              <Form.Select
                                size="sm"
                                name="branch"
                                onChange={handleChange}
                                value={values.branch}
                                isInvalid={!!touched.branch && !!errors.branch}
                              >
                                <option value={""}>Select</option>

                                {getBranchResponse &&
                                  getBranchResponse.data &&
                                  getBranchResponse.data?.data &&
                                  getBranchResponse.data?.data.map((item) => (
                                    <option value={item._id}>
                                      {item.name}
                                    </option>
                                  ))}
                              </Form.Select>
                            </th>
                          </tr>
                          <tr>
                            <th>Student</th>
                            <th>
                              <Form.Select
                                size="sm"
                                name="student"
                                onChange={handleChange}
                                value={values.student}
                                isInvalid={
                                  !!touched.student && !!errors.student
                                }
                              >
                                <option value={""}>Select</option>

                                {getStudentResponse?.data?.data?.map((item) => (
                                  <option value={item._id}>{item.name}</option>
                                ))}
                              </Form.Select>
                            </th>
                          </tr>
                          <tr>
                            <th>Standard</th>
                            <th>
                              <Form.Select
                                size="sm"
                                name="standard"
                                onChange={handleChange}
                                value={values.standard}
                                isInvalid={
                                  !!touched.standard && !!errors.standard
                                }
                                disabled
                              >
                                <option value={""}>Select</option>

                                {getStandardResponse?.data?.data?.map(
                                  (item) => (
                                    <option value={item._id}>
                                      {item.name}
                                    </option>
                                  )
                                )}
                              </Form.Select>
                            </th>
                          </tr>
                          <tr>
                            <th>Year</th>
                            <th>
                              <Form.Control
                                size="sm"
                                type="date"
                                name="year"
                                value={values.year}
                                onChange={handleChange}
                                isInvalid={!!touched.year && !!errors.year}
                              />
                            </th>
                          </tr>
                          <tr>
                            <th>Fees</th>
                            <th>
                              <Form.Control
                                size="sm"
                                type="text"
                                name="fees"
                                value={values.fees}
                                onChange={handleChange}
                                isInvalid={!!touched.fees && !!errors.fees}
                                disabled
                              />
                            </th>
                          </tr>
                          <tr>
                            <th>Discount</th>
                            <th>
                              <Form.Control
                                size="sm"
                                type="number"
                                name="discount"
                                value={values.discount}
                                onChange={(e) => {
                                  if (e.target.value) {
                                    setFieldValue(
                                      "discount",
                                      parseInt(e.target.value)
                                    );
                                    setFieldValue(
                                      "total",

                                      values.fees +
                                        (values.fees *
                                          parseInt(e.target.value)) /
                                          100
                                    );
                                  } else {
                                    setFieldValue("discount", 0);
                                    setFieldValue("total", values.fees);
                                  }
                                }}
                                isInvalid={
                                  !!touched.discount && !!errors.discount
                                }
                                min={0}
                              />
                            </th>
                          </tr>
                          <tr>
                            <th>Total</th>
                            <th>
                              <Form.Control
                                size="sm"
                                type="text"
                                name="total"
                                value={values.total}
                                onChange={handleChange}
                                isInvalid={!!touched.total && !!errors.total}
                                disabled
                              />
                            </th>
                          </tr>
                          <tr>
                            <th>Submit</th>
                            <th>
                              <Button type="submit" size="sm">
                                SUBMIT
                              </Button>
                            </th>
                          </tr>
                        </tbody>
                      </Table>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </>
  );
};

const CalculateTotal: React.FC<{
  setFieldValue: (name: string, value: string | number) => void;
  fees: number;
  discount: number;
}> = ({ discount, fees, setFieldValue }) => {
  useEffect(() => {
    (async () => {
      if (fees && discount) {
        let temp = fees - (fees * discount) / 100;
        setFieldValue("total", temp);
      }
    })();
  }, [fees, discount]);

  return <></>;
};

const GetFeesByStandardAndYear: React.FC<{
  year: string;
  standard: string;
  getStandardByStandardAndYear: FetchData<GetFeesMasterByIdRoot>;
  setFieldValue: (name: string, value: number | string) => void;
}> = ({ getStandardByStandardAndYear, standard, year, setFieldValue }) => {
  useEffect(() => {
    (async () => {
      if (year && standard) {
        await getStandardByStandardAndYear("/getByStandardAndYear", {
          year: year,
          standard: standard,
        })
          .then((res) => {
            if (res.success) {
              setFieldValue("fees", parseInt(res.data.fees));
              setFieldValue("discount", 0);
              setFieldValue("total", parseInt(res.data.fees));
            }
          })
          .catch((err) => console.log(err));
      }
    })();
  }, []);

  return <></>;
};

const SetStandardByStudent: React.FC<{
  studentId: string;
  studentData: GetAllStudentDaum[];
  setFieldValue: (name: string, value: string | number) => void;
  name: string;
}> = ({ setFieldValue, studentData, studentId, name }) => {
  useEffect(() => {
    if (studentId) {
      setFieldValue(
        name,
        studentData.find((x) => x._id === studentId)?.standard._id!
      );
      setFieldValue("fees", 0);
      setFieldValue("discount", 0);
      setFieldValue("total", 0);
      setFieldValue("year", "");
    }
  }, [studentId]);

  return <></>;
};

const GetStudentByBranch: React.FC<{
  branchId: string;
  getStudentByBranch: FetchData<GetAllStudentRoot>;
  setFieldValue: (name: string, value: string | number) => void;
  name: string;
}> = ({ branchId, getStudentByBranch, name, setFieldValue }) => {
  useEffect(() => {
    (async () => {
      await getStudentByBranch(`/byBranch`, {
        branchId: branchId,
      });
      setFieldValue(name, "");
      setFieldValue("standard", "");
      setFieldValue("fees", 0);
      setFieldValue("year", "");
      setFieldValue("discount", 0);
      setFieldValue("total", 0);
    })();
  }, [branchId]);

  return <></>;
};

const Update = () => {
  const navigate = useNavigate();
  const { get, post } = useFetch<GetFeesDiscountMasterByIdRoot>(
    "/feesDiscountMaster"
  );
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const [initValues, setInitValues] = useState({
    discount: 0,
    student: "",
    isActive: false,
  });
  const [key, setKey] = useState(Math.random());
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    (async () => {
      parsed.id && (await fetchData());
    })();
  }, [parsed.id, get]);

  const fetchData = async () => {
    try {
      setIsFetching(true);

      const res = await get(`${parsed.id}`);

      if (res.success) {
        setInitValues({
          isActive: res.data.isActive,
          student: res.data?.student?.name,
          discount: res.data?.discount,
        });

        setKey(Math.random());
      }

      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);

      console.log(err);
    }
  };

  const handleSubmit = async (values: {
    student: String;
    standard: String;
    branch: String;
    year: String;
    fees: number;
    discount: number;
    total: number;
    isActive: Boolean;
  }) => {
    await post(`${parsed.id}`, values)
      .then(async (res) => {
        if (res.success) {
          await get()
            .then((res) => res.success && navigate(-1))
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Update Fees</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    student: Yup.string().required(),
                    standard: Yup.string().required(),
                    branch: Yup.string().required(),
                    year: Yup.string().required(),
                    fees: Yup.number().min(0).required(),
                    discount: Yup.number().min(0).max(100).required(),
                    total: Yup.number().min(0).required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={{
                    student: "",
                    standard: "",
                    branch: "",
                    year: "",
                    fees: 0,
                    discount: 0,
                    total: 0,
                    isActive: true,
                  }}
                  enableReinitialize
                  key={key}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik02"
                          className="mb-3"
                        >
                          <Form.Label>
                            Student <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            disabled
                            type="text"
                            name="student"
                            value={values.student}
                            onChange={handleChange}
                            isValid={touched.student && !errors.student}
                            isInvalid={!!errors.student}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.student}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Discount (%) <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="discount"
                            value={values.discount}
                            onChange={handleChange}
                            isValid={touched.discount && !errors.discount}
                            isInvalid={!!errors.discount}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.discount}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const View = () => {
  return <div></div>;
};

export default FeesDiscountMaster;
