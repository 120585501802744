import { Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { Card, Container, Row, Spinner, Table } from "react-bootstrap";
import useFetch from "use-http";
import AppProvider from "../../components/AppProvider";
import { PaymentDataDaum, PaymentDataRoot } from "../../utils/types";

const PaymentReport = () => {
  const [data, setData] = useState<PaymentDataDaum[]>([]);
  const { get, loading, response } = useFetch<PaymentDataRoot>("/payment");

  useEffect(() => {
    (async () => await fetchData())();
  }, []);

  const fetchData = async () => {
    try {
      const data = await get();
      if (data.success) setData(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  if (loading) {
    return (
      <>
        <AppProvider>
          <Container fluid>
            <Spinner />
          </Container>
        </AppProvider>
      </>
    );
  }

  return (
    <>
      <AppProvider>
        <Container fluid>
          <Card className="mt-3">
            <Card.Header>
              <Row>
                <h6>Payment Report</h6>

                <Formik
                  initialValues={{
                    fromDate: "",
                    toDate: "",
                  }}
                  onSubmit={() => console.log("")}
                >
                  {() => <></>}
                </Formik>
              </Row>
            </Card.Header>
            <Card.Body>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Order Id</th>
                    <th>Tracking Id</th>
                    <th>Payment Status</th>
                    <th>Amount</th>
                    <th>Transaction Date</th>
                    <th>Parent</th>
                    <th>Student</th>
                    <th>Standard</th>
                    <th>Branch</th>
                    <th>Year</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((x) => (
                    <tr>
                      <td>{x.orderId}</td>
                      <td>{x.trackingId}</td>
                      <td>{x.status}</td>
                      <td>{x.amount}</td>
                      <td>{x.transactionDate}</td>
                      <td>{x.parent.name}</td>
                      <td>{x.student.name}</td>
                      <td>{x.student.standard.name}</td>
                      <td>{x.student.branch.name}</td>
                      <td>{moment(x.year).format("YYYY")}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Container>
      </AppProvider>
    </>
  );
};

export default PaymentReport;
