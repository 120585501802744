import { Formik } from "formik";
import moment from "moment";
import queryString from "query-string";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import useFetch from "use-http";
import * as Yup from "yup";
import AppProvider from "../../components/AppProvider";
import CustomPageLayout from "../../components/CustomPageLayout";
import {
  AddDesignationRoot,
  FeesMasterDaum,
  FeesMasterRoot,
  GetAllDesignationAPIRoot,
  GetFeesMasterByIdRoot,
} from "../../utils/types";

const FeesMaster = () => {
  return (
    <CustomPageLayout Add={Add} Index={Index} Update={Update} View={View} />
  );
};

const Index = () => {
  const navigate = useNavigate();
  const { get, del, response, loading } =
    useFetch<FeesMasterRoot>("/feesMaster");
  const [refreshing, setRefreshing] = useState(true);
  const [data, setData] = useState<FeesMasterDaum[]>([]);

  useEffect(() => {
    (async () => await getData())();
  }, [get]);

  const getData = async () => {
    try {
      setRefreshing(true);

      const res = await get();
      if (res.success) {
        setData(res.data);
        setTimeout(() => {
          setRefreshing(false);
        }, 1000);
      } else {
        setRefreshing(false);
        alert(res.message);
      }
    } catch (err) {
      setRefreshing(false);

      console.log(err);
    }
  };

  const handleDelete = async (_id: string) => {
    try {
      setRefreshing(true);

      await del(`${_id}`);

      await getData();

      setRefreshing(false);
    } catch (err) {
      setRefreshing(false);

      console.log(err);
    }
  };

  if (loading || refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "3rem",
                    alignItems: "center",
                  }}
                >
                  <h5>Fees</h5>

                  {true && (
                    <Button onClick={() => navigate("/fees?action=add")}>
                      ADD
                    </Button>
                  )}
                </div>
              </Card.Header>

              <Card.Body>
                <Table bordered hover responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Standard</th>
                      <th>Year</th>
                      <th>Fees</th>
                      <th>Is Active</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data
                      ?.sort((a, b) => {
                        if (a.year > b.year) return -1;
                        if (a.year < b.year) return 1;
                        return 0;
                      })
                      .map((item, index) => (
                        <tr key={item._id}>
                          <td>{index + 1}</td>
                          <td>{item?.standard?.name || ""}</td>
                          <td>{moment(item?.year).format("YYYY") || ""}</td>
                          <td>{item?.fees || ""}</td>
                          <td>{item.isActive ? "Yes" : "No"}</td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                gap: "1rem",
                              }}
                            >
                              <Button
                                onClick={() =>
                                  navigate(
                                    `/fees?action=update&&id=${item._id}`
                                  )
                                }
                              >
                                UPDATE
                              </Button>
                              <Button
                                variant="danger"
                                onClick={() => {
                                  Swal.fire({
                                    title: "Are you sure?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, delete it!",
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      handleDelete(item._id);
                                    }
                                  });
                                }}
                              >
                                DELETE
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const Add = () => {
  const navigate = useNavigate();
  const { post } = useFetch<AddDesignationRoot>("/feesMaster");
  const { get, response, loading } =
    useFetch<GetAllDesignationAPIRoot>("/standard");
  const [isFetching, setIsFetching] = useState(false);
  const [key, setKey] = useState(Math.random());

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  const fetchData = async () => {
    try {
      setIsFetching(true);

      await get();

      setKey(Math.random());

      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
    }
  };

  const handleSubmit = async (values: {
    standard: string;
    fees: string;
    year: string;
    isActive: boolean;
  }) => {
    try {
      setIsFetching(true);

      const res = await post(values);

      if (res.success) {
        navigate(-1);
      } else {
        Swal.fire({
          title: "Error",
          text: res.message || "Something went wrong on server.",
          icon: "error",
        });
      }

      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);

      console.log(err);
    }
  };

  if (loading || isFetching) {
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <AppProvider>
        <Container
          fluid
          style={{
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Card>
            <Card.Body>
              <Spinner />
            </Card.Body>
          </Card>
        </Container>
      </AppProvider>
    </div>;
  }

  return (
    <div key={key}>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Add Fees</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    fees: Yup.string().required(),
                    year: Yup.string().required(),
                    standard: Yup.string().required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={{
                    fees: "",
                    year: "",
                    standard: "",
                    isActive: true,
                  }}
                >
                  {({
                    handleSubmit,
                    handleBlur,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onSubmit={handleSubmit}
                    >
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group
                          as={Col}
                          md="2"
                          controlId="validationFormik02"
                          className="mb-3"
                        >
                          <Form.Label>Standard</Form.Label>
                          <Form.Select
                            size="sm"
                            aria-label="Default select example"
                            name="standard"
                            onChange={handleChange}
                            value={values.standard}
                            isInvalid={!!touched.standard && !!errors.standard}
                          >
                            <option value={""}>Select</option>

                            {response?.data?.data?.map((item) => (
                              <option value={item._id}>{item.name}</option>
                            ))}
                          </Form.Select>
                          {!!touched.standard && !!errors.standard && (
                            <Form.Control.Feedback type="invalid">
                              {errors.standard}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="2"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Year <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="year"
                            value={values.year}
                            onChange={handleChange}
                            isInvalid={!!touched.year && !!errors.year}
                          />
                          {!!touched.year && !!errors.year && (
                            <Form.Control.Feedback type="invalid">
                              {errors.year}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="2"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Amount <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="fees"
                            value={values.fees}
                            onChange={handleChange}
                            isInvalid={!!touched.fees && !!errors.fees}
                          />
                          {!!touched.fees && !!errors.fees && (
                            <Form.Control.Feedback type="invalid">
                              {errors.fees}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>

                        <Form.Group as={Col} md="2" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const Update = () => {
  const navigate = useNavigate();
  const { get, post } = useFetch<GetFeesMasterByIdRoot>("/feesMaster");
  const {
    get: getStandard,
    response,
    loading,
  } = useFetch<GetAllDesignationAPIRoot>("/standard");
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const [initValues, setInitValues] = useState({
    standard: "",
    fees: "",
    year: "",
    isActive: false,
  });
  const [key, setKey] = useState(Math.random());
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    (async () => {
      parsed.id && (await fetchData());
    })();
  }, [parsed.id, get]);

  const fetchData = async () => {
    try {
      setIsFetching(true);

      await getStandard();

      const res = await get(`${parsed.id}`);

      if (res.success) {
        setInitValues({
          isActive: res.data.isActive,
          fees: res.data.fees,
          year: res.data.year,
          standard: res.data.standard,
        });

        setKey(Math.random());
      }

      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);

      console.log(err);
    }
  };

  const handleSubmit = async (values: { fees: string; isActive: boolean }) => {
    try {
      setIsFetching(true);

      await post(`${parsed.id}`, values);

      navigate(-1);

      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);

      console.log(err);
    }
  };

  if (loading || isFetching) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
      </>
    );
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Update Fees</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    fees: Yup.string().required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={initValues}
                  enableReinitialize
                  key={key}
                >
                  {({
                    handleSubmit,
                    handleBlur,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onSubmit={handleSubmit}
                    >
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group
                          as={Col}
                          md="2"
                          controlId="validationFormik02"
                          className="mb-3"
                        >
                          <Form.Label>Standard</Form.Label>
                          <Form.Select
                            disabled
                            size="sm"
                            aria-label="Default select example"
                            name="standard"
                            onChange={handleChange}
                            value={values.standard}
                            isInvalid={!!touched.standard && !!errors.standard}
                          >
                            <option value={""}>Select</option>

                            {response?.data?.data?.map((item) => (
                              <option value={item._id}>{item.name}</option>
                            ))}
                          </Form.Select>
                          {!!touched.standard && !!errors.standard && (
                            <Form.Control.Feedback type="invalid">
                              {errors.standard}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="2"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Year <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            disabled
                            type="date"
                            name="year"
                            value={values.year}
                            onChange={handleChange}
                            isInvalid={!!touched.year && !!errors.year}
                          />
                          {!!touched.year && !!errors.year && (
                            <Form.Control.Feedback type="invalid">
                              {errors.year}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="2"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Fees <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="fees"
                            value={values.fees}
                            onChange={handleChange}
                            isInvalid={!!touched.fees && !!errors.fees}
                          />
                          {!!touched.fees && !!errors.fees && (
                            <Form.Control.Feedback type="invalid">
                              {errors.fees}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>

                        <Form.Group as={Col} md="2" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const View = () => {
  return <div></div>;
};

export default FeesMaster;
